<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { SystemSettingsModel } from '@/modules/system-settings/system-settings-model';

    const { fields } = SystemSettingsModel;

    export default {
        name: 'DecisionTargetListTable',
        data() {
            return {
                presenter: SystemSettingsModel.presenter,
                actions: [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    }
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'systemSettings/list/rows',
            }),
            fields() {
                return [
                    fields.key,
                    fields.description,
                    fields.default_value
                ]
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'systemSettings/form/select'
            }),
            async onEdit(data) {
                await this.selectRecord(data.item);
            }
        },
    };
</script>

<style scoped>

</style>
