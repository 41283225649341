<template>
  <div>
    <system-settings-form-modal
      v-if="showModal"
      ref="systemSettingsFormModal"
      @close="dismissModal"
      :edit-mode="editMode"
    ></system-settings-form-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SystemSettingsFormModal from "@/modules/system-settings/components/SystemSettingsFormModal.vue";

export default {
  name: "SystemSettingsListToolbar",
  components: { SystemSettingsFormModal },
  watch: {
    openEditing(newValue, oldValue) {
      if (newValue === oldValue || !newValue) return false;
      this.editMode = true;
      this.showModal = true;
    },
  },
  data() {
    return {
      showModal: false,
      editMode: false,
    };
  },
  computed: {
    ...mapGetters({
      record: "systemSettings/form/record",
      openEditing: "systemSettings/form/openEditing",
    }),
  },
  methods: {
    ...mapActions({
      unselectRecord: "systemSettings/form/unselect",
    }),
    dismissModal() {
      this.showModal = false;
      if (this.editMode) {
        this.unselectRecord();
      }
    },
  },
};
</script>

