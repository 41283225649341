<template>
  <st-page :title="$t('MENU.SYSTEM_SETTINGS')">
    <loader v-if="isLoading" />
    <system-settings-list-filter ref="systemSettingsListFilter">
       <template #toolbar>
        <system-settings-list-toolbar />
      </template>
      <system-settings-list-table @update="updateList"/>
    </system-settings-list-filter>
  </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import SystemSettingsListTable from "@/modules/system-settings/components/SystemSettingsListTable.vue";
import SystemSettingsListFilter from "@/modules/system-settings/components/SystemSettingsListFilter.vue";
import SystemSettingsListToolbar from "@/modules/system-settings/components/SystemSettingsListToolbar.vue";

export default {
  name: "SystemSettingsListList",
  components: {
      SystemSettingsListTable,
      SystemSettingsListFilter,
      SystemSettingsListToolbar
  },
  computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['systemSettings/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
    methods: {
      updateList() {
        this.$refs.systemSettingsListFilter.refresh();
      },
    },
};
</script>
