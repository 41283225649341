import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`SYSTEM_SETTINGS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`SYSTEM_SETTINGS.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    key: new StringField('key', label('key'), {
        placeholder: placeholder('key'),
    }),
    description: new StringField('description', label('description'), {
        placeholder: placeholder('description'),
    }),
    default_value: new StringField('default_value', label('default_value')),
    setting_type: new StringField('setting_type')
};

export class SystemSettingsModel extends GenericModel {
    static get fields() {
        return fields;
    }

    static presenter(row, fieldName) {
        const fieldExists = this.fields.some(field => field.name === fieldName);
        if (!fieldExists) {
            throw new Error(`${fieldName} not found`);
        }
        const field = this.fields.find((el) => el.name === fieldName);
        let record;
        if (field.name === fields.key.name) {
            record = i18n.t(`SYSTEM_SETTINGS.KEYS.${row[field.name]}`);
        }
        else if (field.name === fields.default_value.name) {
            record = row[fields.setting_type.name] === 'boolean' ? row[field.name] ? i18n.t('GENERAL.ACTIVE') : i18n.t('GENERAL.INACTIVE') : row[field.name]
        } else {
            record = row[field.name];
        }
        return field.forPresenter(record);
    }
}
